import Vue from 'vue'
import Router from 'vue-router'
import menuCategories from '@/menuCategories.js'
import RecipePage from './views/RecipePage.vue'
import MagazinePage from './views/MagazinePage.vue'
import Magazine from './views/Magazine.vue'
import Recipe from './views/Recipe.vue'
import Login from './views/Login.vue'
import ItemPage from './views/ProductPage.vue'
import RestaurantPage from './views/RestaurantPage.vue'
import Home from './views/Home.vue'
import Restaurants from './views/Restaurants.vue'
import OrgPage from './views/OrgPage.vue'

Vue.use(Router)

const categories = Object.entries(menuCategories.categories).map(
  ([key, value]) => value.link
)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login/:code',
      name: 'login',
      component: Login
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/restaurants',
      name: 'restaurants',
      component: Restaurants
    },
    {
      path: '/magazine',
      name: 'magazine',
      component: Magazine
    },
    {
      path: '/recipes',
      name: 'recipes',
      component: Recipe
    },
    {
      path: `/:categoryName(${categories.join('|')})`,
      name: 'categoryPage',
      component: Home
    },
    {
      path: '/search/:query?',
      name: 'searchPage',
      component: Home
    },
    {
      path: '/:slug',
      name: 'itemPage',
      component: ItemPage
    },
    {
      path: '/tag/:tag',
      name: 'tagPage',
      component: Home
    },
    {
      path: '/recipe/:slug',
      name: 'recipePage',
      component: RecipePage
    },
    {
      path: '/mag/:slug',
      name: 'magazinePage',
      component: MagazinePage
    },
    {
      path: '/restaurant/:slug',
      name: 'restaurantPage',
      component: RestaurantPage
    },
    {
      path: '/brand/:slug',
      name: 'orgPage',
      component: OrgPage
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.afterEach((to) => {
  window.analytics.page(to.name, {}, {
    integrations: {
      'All': true,
      'Algolia': false
    }
  })
})

export default router
