<template>
  <div class="home">
    <BigBanner
      :collection="collection"
    />
    <h1 v-if="$route.params.tag">
      {{ $route.params.tag ? `#${$route.params.tag}` : '' }}
    </h1>
    <Listing
      :query="$route.params.query"
      :collection="$store.state.collectionNames"
      :tag="this.$route.name === 'tagPage' ? this.$route.params.tag : null"
      :userLogged="$store.getters.userLoggedIn"
      @addItemToCart="$emit('addProductToCart', $event)"
    />
  </div>
</template>

<script>
import BigBanner from '@/components/BigBanner.vue'
import { getMetaTags } from '@/seo.js'
import { createWidgetMixin } from 'vue-instantsearch'
import { connectInfiniteHits } from 'instantsearch.js/es/connectors'
import httpClient from '@/services/httpClient'

export default {
  name: 'Home',
  components: {
    BigBanner
  },
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  data () {
    return {
      seo: {
        metaTitle: 'The UK’s Favourite Vegan Marketplace - Milq',
        metaDescription: 'We deliver plant-based foods straight to your door to help you save time and make your life easier.',
      },
      collection: null
    }
  },
  computed: {
    filters () {
      if (this.isCollection) {
        return `collections:${this.$store.state.collectionNames}`
      } else if (this.$route.name === 'tagPage') {
        return `tags:${this.$route.params.tag}`
      }

      return ''
    },
    isCollection () {
      return !['tagPage', 'searchPage'].includes(this.$route.name) &&
        this.$store.state.collectionNames.length > 0
    }
  },
  created () {
    if (this.$route.name === 'home') {
      this.$store.commit('setCollectionNames', 'milq_homepage')
    }
  },
  mounted () {
    const { tag } = this.$route.params
    if (tag) {
      this.seo = {
        ...this.seo,
        metaTitle: tag
      }
    }

    this.$nextTick(() => {
      if (this.isCollection) {
        this.getCollection(this.$store.state.collectionNames)
      }
    })

    window.analytics.identify(this.$store.state.userData.id, {
      name: this.$store.state.userData.name,
      email: this.$store.state.userData.email
    }, {
      integrations: {
        'All': true,
        'Algolia': false
      }
    })
  },
  methods: {
    getCollection (name) {
      httpClient
        .get(`/item-collection/${name}`)
        .then((response) => {
          this.collection = {
            ...response.data
          }
          this.seo = {
            ...this.seo,
            metaDescription: this.collection.description ? this.collection.description.replace(/\r?\n|\r/, '') : null,
            shareImage: this.collection.pictures ? this.collection.pictures[0] : null,
            metaTitle: this.collection.title
          }
        })
    }
  },
  metaInfo () {
    return {
      title: `${this.seo.metaTitle || 'The UK’s Favourite Vegan Marketplace'} - Milq`,
      meta: getMetaTags(this.seo)
    }
  }
}
</script>

<style lang="stylus" scoped>
.home
  margin 0 auto
  h1
    text-align left
    font-size 25px
  &__cardContainer
    display grid
    justify-content center
    align-items center
    margin 0 auto
    gap 30px
    grid-template 1fr / 1fr 1fr 1fr 1fr
    padding-bottom 30px
  &__cardContainer-empty
    margin 20px 0 30px 0
    i
      font-size 50px
@media screen and (max-width: 780px)
  .home__cardContainer
    grid-template: unquote("1fr / minmax(0, 1fr) minmax(0, 1fr)")
    max-width 460px
@media screen and (max-width: 500px)
  .home__cardContainer
    gap 10px
</style>
