var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productCard",class:{ restaurantCard: _vm.restaurant, magazineCard: _vm.magazine },on:{"click":_vm.onProductClicked}},[_c('router-link',{attrs:{"to":_vm.isRecipe
        ? ("/recipe/" + (_vm.product.slug))
        : _vm.magazine
        ? ("/mag/" + (_vm.product.slug))
        : _vm.restaurant
        ? ("/restaurant/" + (_vm.product.slug))
        : ("/" + (_vm.product.slug))}},[_c('div',{staticClass:"productCard__image",style:({
        backgroundSize: ("" + (_vm.magazine || _vm.restaurant ? 'cover' : '')),
        backgroundImage: ("url(" + (_vm.imgix(_vm.product.pictures[0], {
          width: 242,
          height: 242,
        })) + ")"),
      })})]),_c('div',{staticClass:"productCard__info",class:{ restaurantCardInfo: _vm.restaurant }},[_c('router-link',{attrs:{"to":_vm.isRecipe
          ? ("/recipe/" + (_vm.product.slug))
          : _vm.magazine
          ? ("/mag/" + (_vm.product.slug))
          : _vm.restaurant
          ? ("/restaurant/" + (_vm.product.slug))
          : ("/" + (_vm.product.slug))}},[_c('h4',[_vm._v(_vm._s(_vm.trimmedName))])]),(_vm.restaurant)?_c('div',{staticClass:"productCard__location"},[_vm._v("\n      "+_vm._s(("restaurant in " + (_vm.product.address.street_address) + " - " + (_vm.product.address.city)))+"\n    ")]):_vm._e(),(_vm.restaurant === false)?_c('div',[(_vm.product.owner && _vm.product.owner.slug)?_c('router-link',{attrs:{"to":("/brand/" + (_vm.product.owner.slug))}},[_c('h6',[_vm._v(_vm._s(_vm.product.owner.name))])]):_c('h6',[_vm._v(_vm._s(_vm.product.owner.name))])],1):_vm._e(),_c('div',{staticClass:"productCard__tags"},_vm._l((_vm.product.tags),function(tag){return _c('div',{key:("/tag/" + tag + "/"),staticClass:"productCard__tag",on:{"click":function($event){return _vm.$router.push(("/tag/" + tag))}}},[_vm._v("\n        "+_vm._s("#" + tag)+"\n      ")])}),0)],1),(_vm.isOutOfStock && !_vm.shouldShowGrey)?_c('div',{staticClass:"out-of-stock-badge"},[_vm._v("\n    Out of Stock\n  ")]):_vm._e(),(_vm.offerings && _vm.offerings.length)?_c('div',{staticClass:"productCard__price"},[_c('span',{staticStyle:{"text-decoration":"line-through","margin":"0 5px","font-size":"1em"}},[_vm._v("\n      "+_vm._s(_vm.offering.compare_at_price > _vm.offering.price
          ? ((_vm.getCurrencySymbol(_vm.offering.currency)) + " " + (_vm.getPrice(
              _vm.offering.compare_at_price,
              _vm.offering.currency
            )))
          : "")+"\n    ")]),_c('span',[_c('strong',[_vm._v(_vm._s(_vm.getCurrencySymbol(_vm.offering.currency))+"\n        "+_vm._s(_vm.getPrice(_vm.offering.price, _vm.offering.currency))+"\n      ")])])]):(_vm.isBooking)?_c('div',{staticClass:"productCard__price"},[_c('span',[_c('b',[_vm._v("\n        "+_vm._s(_vm.getCurrencySymbol(_vm.product.currency))+"\n        "+_vm._s(_vm.getPrice(_vm.product.price, _vm.product.currency))+"\n      ")])])]):(_vm.isProduct && !_vm.offerings)?_c('div',{staticClass:"productCard__price loading"},[_c('i',{staticClass:"fal fa-spinner fa-spin"})]):_vm._e(),(_vm.isProduct && _vm.offerings && !_vm.offerings.length)?_c('div',{staticClass:"out-of-stock-badge"},[_vm._v("\n    No offers available\n  ")]):_vm._e(),_c('div',{staticClass:"productCard__socialBottom"},[_c('span',{staticClass:"productCard__actions"},[_vm._v("\n      "+_vm._s(_vm.checkIsOutOfStock())+"\n      "),(_vm.isProductOffering())?_c('button',{staticClass:"productCard__transparentButton addToCartButton",attrs:{"title":"Add to cart","disabled":_vm.isOutOfStock},on:{"click":_vm.addProductToCart}},[_c('i',{staticClass:"fal fa-plus-circle"})]):_vm._e(),(_vm.isBooking)?_c('Booking',{attrs:{"itemData":_vm.product,"simpleButton":true},on:{"addItemToCart":_vm.addProductToCart}}):_vm._e(),_c('button',{staticClass:"productCard__transparentButton productCard__actionCounter",attrs:{"disabled":"disabled"}},[_c('i',{staticClass:"fa-heart",class:_vm.product.user_upvoted ? 'fas likeAnimation' : 'fal'}),_c('div',{staticClass:"productCard__likeCounter"},[_c('p',[_vm._v(_vm._s(_vm.product.upvotes ? _vm.product.upvotes : 0))]),_c('p',[_vm._v(_vm._s(_vm.product.upvotes ? _vm.product.upvotes : 0))])])]),_c('button',{staticClass:"productCard__transparentButton productCard__actionCounter",attrs:{"disabled":!_vm.$store.getters.isUserLoggedIn,"title":_vm.$store.getters.isUserLoggedIn
            ? 'Comment it'
            : 'Please log in to give it some love'}},[_c('i',{staticClass:"fal fa-comments"}),_c('span',{staticClass:"productCard__commentCounter"},[_vm._v(_vm._s(_vm.product.comments ? _vm.product.comments : 0))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }