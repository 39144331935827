import { render, staticRenderFns } from "./BlackButton.vue?vue&type=template&id=61e75256&scoped=true&"
import script from "./BlackButton.vue?vue&type=script&lang=js&"
export * from "./BlackButton.vue?vue&type=script&lang=js&"
import style0 from "./BlackButton.vue?vue&type=style&index=0&id=61e75256&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e75256",
  null
  
)

export default component.exports