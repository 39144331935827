import { render, staticRenderFns } from "./CornerAlert.vue?vue&type=template&id=764d52ba&scoped=true&"
import script from "./CornerAlert.vue?vue&type=script&lang=js&"
export * from "./CornerAlert.vue?vue&type=script&lang=js&"
import style0 from "./CornerAlert.vue?vue&type=style&index=0&id=764d52ba&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764d52ba",
  null
  
)

export default component.exports