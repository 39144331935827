var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailedProductCard"},[_c('PictureDisplayer',{attrs:{"pictures":_vm.productData.pictures || []}}),(_vm.productData)?_c('div',{staticClass:"detailedProductCard__description"},[_c('h1',[_vm._v(_vm._s(_vm.productData.name))]),(_vm.restaurant)?_c('div',[_c('h2',{staticStyle:{"text-transform":"uppercase","margin-top":"0.5rem"}},[_vm._v(_vm._s(("restaurant in " + (_vm.productData.address.street_address) + " - " + (_vm.productData.address.city))))])]):_c('div',[_c('router-link',{attrs:{"to":("/brand/" + (_vm.productData.owner && _vm.productData.owner.slug
          ? _vm.productData.owner.slug
          : '') + "/")}},[_c('h2',[_vm._v("\n        "+_vm._s(_vm.productData.owner && _vm.productData.owner.name
            ? _vm.productData.owner.name
            : '')+"\n      ")])])],1),_c('div',{staticClass:"detailedProductCard__tags"},_vm._l((_vm.productData.tags),function(tag){return _c('router-link',{key:("/tag/" + tag + "/"),staticClass:"detailedProductCard__tag",attrs:{"to":("/tag/" + tag + "/")}},[_vm._v("\n        "+_vm._s(("#" + tag))+"\n      ")])}),1),_c('button',{staticClass:"detailedProductCard__transparentButton detailedProductCard__actionCounter",class:{ 'detailedProductCard__actionCounter--liked': _vm.productData.user_upvoted },attrs:{"disabled":!_vm.$store.getters.isUserLoggedIn,"title":_vm.$store.getters.isUserLoggedIn
          ? 'Love it'
          : 'Please log in to give it some love'},on:{"click":function($event){return _vm.likeProduct()}}},[_c('i',{staticClass:"fa-heart",class:_vm.productData.user_upvoted ? 'fas likeAnimation' : 'fal'}),_c('div',{staticClass:"detailedProductCard__likeCounter"},[_c('p',[_vm._v(_vm._s(_vm.productData.upvotes ? _vm.productData.upvotes : 0))])])]),(['offer', 'room'].includes(_vm.productData.item_type))?_c('div',{staticClass:"offering"},[_c('Booking',{attrs:{"itemData":_vm.productData},on:{"addItemToCart":_vm.handleAddToCart}}),_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("\n        "+_vm._s(_vm.productData.name)+" -\n      ")]),_c('span',[_c('b',[_vm._v("\n          "+_vm._s(_vm.getCurrencySymbol(_vm.productData.currency))+"\n          "+_vm._s(_vm.getPrice(_vm.productData.price, _vm.productData.currency))+"\n        ")])])],1):_vm._e(),_vm._l((_vm.productData.offerings),function(offering){return _c('div',{key:offering.id,staticClass:"offering"},[_c('span',[_vm._v(_vm._s(offering.name)+" -")]),_c('span',{staticStyle:{"text-decoration":"line-through","margin":"0 5px","font-size":"0.8em"}},[_vm._v(_vm._s(offering.compare_at_price > offering.price
            ? ((_vm.getCurrencySymbol(offering.currency)) + " " + (_vm.getPrice(
                offering.compare_at_price,
                offering.currency
              )))
            : ''))]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.getCurrencySymbol(offering.currency))+"\n          "+_vm._s(_vm.getPrice(offering.price, offering.currency))+"\n        ")])]),_c('div',{staticClass:"offering-break"}),(
          _vm.productData.item_type !== 'article' &&
          _vm.productData.item_type !== 'post' &&
          !_vm.getCartOrderForOffering(offering)
        )?_c('button',{staticClass:"add-to-cart-button",attrs:{"disabled":_vm.isOfferingOutOfStock(offering)},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddToCart(offering)}}},[_vm._v("\n        Add to Cart\n      ")]):_vm._e(),(_vm.getCartOrderForOffering(offering))?_c('div',{staticClass:"detailedProductCard__quantityButtonContainer"},[_c('button',{staticClass:"detailedProductCard__quantityButton",attrs:{"title":"Remove one","aria-label":"Remove one","disabled":_vm.getCartOrderForOffering(offering).offering.quantity <= 1},on:{"click":function($event){_vm.getCartOrderForOffering(offering).offering.quantity--;
            _vm.updateOrderQuantity(offering);}}},[_c('span',{staticStyle:{"transform":"translateY(-2px)"}},[_vm._v("-")])]),_c('b',{staticClass:"detailedProductCard__quantity"},[_vm._v(_vm._s(_vm.getCartOrderForOffering(offering).offering.quantity))]),_vm._v("x\n        "),_c('button',{staticClass:"detailedProductCard__quantityButton",attrs:{"title":"Add one more","aria-label":"Add one more","disabled":_vm.isOfferingOutOfStock(offering)},on:{"click":function($event){_vm.getCartOrderForOffering(offering).offering.quantity++;
            _vm.updateOrderQuantity(offering);}}},[_c('span',[_vm._v("+")])])]):_vm._e(),(_vm.isOfferingOutOfStock(offering) && !_vm.getCartOrderForOffering(offering))?_c('div',{staticClass:"out-of-stock-badge"},[_vm._v("\n          Out of Stock\n      ")]):_vm._e()])}),(!_vm.restaurant && _vm.productData.offerings && !_vm.productData.offerings.length
        && !['offer', 'room'].includes(_vm.productData.item_type))?_c('div',{staticClass:"out-of-stock-badge"},[_vm._v("\n      No offers available\n    ")]):_vm._e(),_c('p',{staticClass:"detailedProductCard__descriptionTop"},[_vm._v(_vm._s(_vm.productData.description))])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }